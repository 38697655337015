import React, { useState } from 'react';
import { Card, View, Badge } from '@aws-amplify/ui-react';
import { Button, Typography } from '@mui/material';
import TableLoadModal from './TableLoadModal';

const DefaultCollectionExample = ({
  user,
  data,
  transactionType,
  manyToOne,
  oneToMany,
  receivingFromMultipleCountries,
  sendingFromMultipleCountries,
  multipleAddresses,
  multipleIds,
  multiplePhoneNumbers,
  sameAddress,
  sameIds,
  samePhoneNumber
}) => {
  //console.log(data)
  const badges = [
    {
      title: 'Multiple phone numbers',
      count: multiplePhoneNumbers ? multiplePhoneNumbers.length : 0,
      data: multiplePhoneNumbers
    },
    {
      title: 'Multiple IDs',
      count: multipleIds ? multipleIds.length : 0,
      data: multipleIds
    },
    {
      title: 'Multiple addresses',
      count: multipleAddresses ? multipleAddresses.length : 0,
      data: multipleAddresses
    },
    {
      title: 'Sharing phone numbers',
      count: samePhoneNumber ? samePhoneNumber.length : 0,
      data: samePhoneNumber
    },
    {
      title: 'Sharing IDs',
      count: sameIds ? sameIds.length : 0,
      data: sameIds
    },
    {
      title: 'Sharing addresses',
      count: sameAddress ? sameAddress.length : 0,
      data: sameAddress
    },
    {
      title: 'Sending to multiple countries',
      count: sendingFromMultipleCountries ? sendingFromMultipleCountries.length : 0,
      data: sendingFromMultipleCountries
    },
    {
      title: 'Receving from multiple countries',
      count: receivingFromMultipleCountries ? receivingFromMultipleCountries.length : 0,
      data: receivingFromMultipleCountries
    }, {
      title: 'Many to One',
      count: manyToOne ? manyToOne.length : 0,
      data: manyToOne
    },
    {
      title: 'One to many',
      count: oneToMany ? oneToMany.length : 0,
      data: oneToMany
    }
  ]

  const [showComponent, setShowComponent] = useState(false);
  const [dataPattern, setDataPattern] = useState([]);
  const [title, setTitle] = useState('');


  const handleButtonClick = (pattern) => {
    setShowComponent(true);
    setDataPattern(pattern.data);
    setTitle(pattern.title);
  };

  return (


    <Card
      borderRadius="medium"
      /* maxWidth="30rem" */
      variation="outlined"
    >

      <View padding="xs" >
        {badges.map((badge) => (
          <>
            <Typography style={{ display: 'flex', justifyContent: 'space-between' }} >
              <Button variant="text" color="inherit" onClick={() => handleButtonClick(badge)}>
                {badge.title} &nbsp;
              </Button>

              <Badge
                key={badge}
                color={'black'}
                backgroundColor={'#DEDDDB'}
              >
                {badge.count}
              </Badge>
            </Typography>


            <br />
          </>

        ))}
        {showComponent && <TableLoadModal user={user} showComponent={showComponent} data={dataPattern} title={title} transactionType={transactionType} setShowComponent={setShowComponent} />}
      </View>
    </Card>

  );
};

export default DefaultCollectionExample;