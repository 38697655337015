import React, { useEffect, useState } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { NativeSelect, TextField } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));
const SelectOtherProps = ({
  data,
  defaultData,
  setData,
  setTransactionType,
  transactionType,
  fraudReason,
  setFraudReason,
  setFilteredData,
  filteredData,
  setFilteredResult
}) => {

  const [dateBackup, setDateBackup] = useState('');
  const [selectedDate, setSelectedDate] = useState(true);

  const handleFraudtypeClick = (value) => {
    setFraudReason(value);
    //alert('ok')
    // Sent transactions
    if (transactionType === 'SENT') {
      if (value === "many_to_one") {
        if (data[0].sent_data.many_to_one.recipients.length > 0) {
          setFilteredData(data[0].sent_data.many_to_one.recipients)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      if (value === "receiving_from_multiple_countries") {
        if (data[0].sent_data.international_transactions.receiving_from_multiple_countries.length > 0) {
          setFilteredData(data[0].sent_data.international_transactions.receiving_from_multiple_countries)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      if (value === "sending_from_multiple_countries") {
        if (data[0].sent_data.international_transactions.sending_from_multiple_countries.length > 0) {
          setFilteredData(data[0].sent_data.international_transactions.sending_from_multiple_countries)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      if (value === "multiple_addresses") {
        if (data[0].sent_data.multiples_patterns.multiple_addresses.length > 0) {
          setFilteredData(data[0].sent_data.multiples_patterns.multiple_addresses)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      if (value === "multiple_ids") {
        if (data[0].sent_data.multiples_patterns.multiple_ids.length > 0) {
          setFilteredData(data[0].sent_data.multiples_patterns.multiple_ids)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      if (value === "multiple_phone_numbers") {
        if (data[0].sent_data.multiples_patterns.multiple_phone_numbers.length > 0) {
          setFilteredData([...data[0].sent_data.multiples_patterns.multiple_phone_numbers])
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      if (value === "same_address") {
        if (data[0].sent_data.sharing_patterns.same_address.length > 0) {
          setFilteredData(data[0].sent_data.sharing_patterns.same_address)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      if (value === "same_ids") {
        if (data[0].sent_data.sharing_patterns.same_ids.length > 0) {
          setFilteredData(data[0].sent_data.sharing_patterns.same_ids)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      if (value === "same_phone_number") {
        if (data[0].sent_data.sharing_patterns.same_phone_number.length > 0) {
          setFilteredData(data[0].sent_data.sharing_patterns.same_phone_number)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      if (value === "ALL") {
        setFilteredData([])
        setFilteredData(filteredData => [
          ...filteredData,
          ...data[0].sent_data.many_to_one.recipients,
          ...data[0].sent_data.international_transactions.receiving_from_multiple_countries,
          ...data[0].sent_data.international_transactions.sending_from_multiple_countries,
          ...data[0].sent_data.multiples_patterns.multiple_addresses,
          ...data[0].sent_data.multiples_patterns.multiple_ids,
          ...data[0].sent_data.multiples_patterns.multiple_phone_numbers,
          ...data[0].sent_data.sharing_patterns.same_address,
          ...data[0].sent_data.sharing_patterns.same_ids,
          ...data[0].sent_data.sharing_patterns.same_phone_number,
          ...data[0].paid_data.many_to_one.recipients,
          ...data[0].paid_data.international_transactions.receiving_from_multiple_countries,
          ...data[0].paid_data.international_transactions.sending_from_multiple_countries,
          ...data[0].paid_data.multiples_patterns.multiple_addresses,
          ...data[0].paid_data.multiples_patterns.multiple_ids,
          ...data[0].paid_data.multiples_patterns.multiple_phone_numbers,
          ...data[0].paid_data.sharing_patterns.same_address,
          ...data[0].paid_data.sharing_patterns.same_ids,
          ...data[0].paid_data.sharing_patterns.same_phone_number
        ]);
      }
    }
    // Received transaction
    else if (transactionType === 'PAID') {
      if (value === "many_to_one") {
        if (data[0].paid_data.many_to_one.recipients.length > 0) {
          setFilteredData(data[0].paid_data.many_to_one.recipients)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      else if (value === "receiving_from_multiple_countries") {
        if (data[0].paid_data.international_transactions.receiving_from_multiple_countries.length > 0) {
          setFilteredData(data[0].paid_data.international_transactions.receiving_from_multiple_countries)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      else if (value === "sending_from_multiple_countries") {
        if (data[0].paid_data.international_transactions.sending_from_multiple_countries.length > 0) {
          setFilteredData(data[0].paid_data.international_transactions.sending_from_multiple_countries)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      else if (value === "multiple_addresses") {
        if (data[0].paid_data.multiples_patterns.multiple_addresses.length > 0) {
          setFilteredData(data[0].paid_data.multiples_patterns.multiple_addresses)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }
      }
      else if (value === "multiple_ids") {
        if (data[0].paid_data.multiples_patterns.multiple_ids.length > 0) {
          setFilteredData(data[0].paid_data.multiples_patterns.multiple_ids)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      else if (value === "multiple_phone_numbers") {
        if (data[0].paid_data.multiples_patterns.multiple_phone_numbers.length > 0) {
          setFilteredData(data[0].paid_data.multiples_patterns.multiple_phone_numbers)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      else if (value === "same_address") {
        if (data[0].paid_data.sharing_patterns.same_address.length > 0) {
          setFilteredData(data[0].paid_data.sharing_patterns.same_address)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      else if (value === "same_ids") {
        if (data[0].paid_data.sharing_patterns.same_ids.length > 0) {
          setFilteredData(data[0].paid_data.sharing_patterns.same_ids)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      else if (value === "same_phone_number") {
        if (data[0].paid_data.sharing_patterns.same_phone_number.length > 0) {
          setFilteredData(data[0].paid_data.sharing_patterns.same_phone_number)
        } else {
          setFilteredData([]);
          setFilteredResult([]);
        }

      }
      else if (value === "ALL") {
        setFilteredData([])
        setFilteredData(filteredData => [
          ...filteredData,
          ...data[0].sent_data.many_to_one.recipients,
          ...data[0].sent_data.international_transactions.receiving_from_multiple_countries,
          ...data[0].sent_data.international_transactions.sending_from_multiple_countries,
          ...data[0].sent_data.multiples_patterns.multiple_addresses,
          ...data[0].sent_data.multiples_patterns.multiple_ids,
          ...data[0].sent_data.multiples_patterns.multiple_phone_numbers,
          ...data[0].sent_data.sharing_patterns.same_address,
          ...data[0].sent_data.sharing_patterns.same_ids,
          ...data[0].sent_data.sharing_patterns.same_phone_number,
          ...data[0].paid_data.many_to_one.recipients,
          ...data[0].paid_data.international_transactions.receiving_from_multiple_countries,
          ...data[0].paid_data.international_transactions.sending_from_multiple_countries,
          ...data[0].paid_data.multiples_patterns.multiple_addresses,
          ...data[0].paid_data.multiples_patterns.multiple_ids,
          ...data[0].paid_data.multiples_patterns.multiple_phone_numbers,
          ...data[0].paid_data.sharing_patterns.same_address,
          ...data[0].paid_data.sharing_patterns.same_ids,
          ...data[0].paid_data.sharing_patterns.same_phone_number
        ]);
      }
    }
    else {
      setFilteredData([]);
      setFilteredResult([]);
    }
  };


  const handleDateChange = (value) => {
    setDateBackup(value)
    if (value) {
      setSelectedDate(false)
      const filteredResult = defaultData.filter((item, key) => key === (value - 1))
      setData(filteredResult[0].data)
    } else {
      setSelectedDate(true)
    }
    
    /* setStartDate(value)
    filteredResult = filteredData.filter((item) => (item.paidDate ? item.paidDate : item.sentDate).slice(0, 10) === value)
    setFilteredResult(filteredResult); */
    //setFilteredData(filteredResult);
  };

  return (
    <div className='select'>
      {/* <FormControl sx={{ m: 1, minWidth: '100' }} variant="standard">
        <InputLabel id="backup-date-input">Backup Date</InputLabel>
        <Select
          labelId="backup-date-input"
          id="demo-simple-select-readonly"
          value={dateBackup}
          input={<BootstrapInput />}
          onChange={(e) => handleDateChange(e.target.value)}
        >
          {defaultData.map((backup, index)=> 
          <MenuItem value={ index + 1 }>{backup.date}</MenuItem>
          )}
        </Select>
        <FormHelperText>Select the Date of Backup</FormHelperText>
      </FormControl> */}
      <FormControl sx={{ m: 1, minWidth: '100' }} variant="standard">
        <InputLabel id="transaction-type-backup">Transaction type</InputLabel>
        <Select
          labelId="transaction-type-backup"
          id="demo-simple-select-readonly"
          /* disabled={selectedDate} */
          value={transactionType}
          input={<BootstrapInput />}
          onChange={(e) => setTransactionType(e.target.value)}
        >
          <MenuItem value="ALL">
            <em>ALL</em>
          </MenuItem>
          <MenuItem value="PAID">Received Transactions</MenuItem>
          <MenuItem value="SENT">Sent Transactions</MenuItem>
        </Select>
        <FormHelperText>Select the transaction type</FormHelperText>
      </FormControl>
      <FormControl sx={{ m: 1, minWidth: '100' }} variant="standard">
        <InputLabel id="demo-simple-select-disabled-label">Patterns</InputLabel>
        <Select
          labelId="demo-simple-select-disabled-label"
          id="demo-simple-select-disabled"
          /* disabled={selectedDate} */
          value={fraudReason}
          input={<BootstrapInput />}
          onChange={(e) => handleFraudtypeClick(e.target.value)}
        >
          <MenuItem value="ALL">
            <em>ALL</em>
          </MenuItem>
          <MenuItem value="same_phone_number">Sharing phone numbers</MenuItem>
          <MenuItem value="same_ids">Sharing IDs</MenuItem>
          <MenuItem value="same_address">Sharing addresses</MenuItem>
          <MenuItem value="multiple_ids">Multiple IDs</MenuItem>
          <MenuItem value="multiple_phone_numbers">Multiple phone numbers</MenuItem>
          <MenuItem value="multiple_addresses">Multiple Addresses</MenuItem>
          <MenuItem value="one_to_many">One-to-many</MenuItem>
          <MenuItem value="many_to_one">Many-to-one</MenuItem>
          <MenuItem value="receiving_from_multiple_countries">Receiving from different countries</MenuItem>
          <MenuItem value="sending_from_multiple_countries">Sending to different countries</MenuItem>
        </Select>
        <FormHelperText>Select the Patterns</FormHelperText>
      </FormControl>


    </div>
  );
}
export default SelectOtherProps;