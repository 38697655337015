import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Switch, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

const CenteredContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column', // Center vertically and horizontally
  textAlign: 'center', // Center text
});

const MainListItems = ({ setViewreport, viewreport, open, setOpen }) => {
  const dashboardDrawer = () => {
    setOpen(!open);
  };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#D81E23',
      '&:hover': {
        backgroundColor: alpha('#D81E23', theme.palette.action.hoverOpacity),
        borderRadius: '25%',
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#D81E23',
    },
  }));

  const PinkDashboardIcon = styled(DashboardIcon)({
    marginTop: '20px', // Add margin top
    color: '#D81E23',
    width: 50,
    height: 50,
    backgroundColor: '#FCD2D4', // Background color like PinkSwitch
    borderRadius: '50%', // Border radius
    padding: '5px', // Add padding for visual space
  });

 
  return (
    <CenteredContainer>
      <PinkDashboardIcon />
      {open ? <Typography
        marginTop={3}
        component="h6"
        variant="h6"
        color="inherit"
        noWrap
        sx={{ flexGrow: 1 }}
      >
        Dashboard
      </Typography> : ''}
      <ListItemButton onClick={dashboardDrawer} style={{ marginTop: '20px' }}>
        <PinkSwitch checked={open} />
      </ListItemButton>
    </CenteredContainer>
  );
};

export default MainListItems;
