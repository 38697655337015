import React, { useState } from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MainListItems from './listItems';
import Deposits from './Deposits';
import Orders from './Orders';
import SelectOtherProps from './SelectOtherProps';
import { SupervisedUserCircleOutlined } from '@mui/icons-material';
import { Autocomplete, Button, FormControl, FormHelperText, Switch, TextField } from '@mui/material';
import DefaultCollectionExample from './DefaultCollectionExample';
import { Loader, Text } from '@aws-amplify/ui-react';
import LoadingScreen from './LoadingScreen';
import CsvPreview from './CsvPreview';
import LogoImage from '../logo.jpeg';
import Chart from './Chart';
import TotalPatterns from './TotalPattern';
import Filter from './Filter';
import LogoutIcon from '@mui/icons-material/Logout';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://skaletek.com/">
        Skaletek
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 150;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  marginLeft: drawerWidth,
  width: `calc(100% - 72px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);


const defaultTheme = createTheme();

const Dashboard = ({
  loading,
  data,
  defaultData,
  setData,
  user
}) => {
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };



  const [filteredData, setFilteredData] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [manyToOne, setManyToOne] = useState([]);
  const [oneToMany, setOneToMany] = useState([]);
  const [receivingFromMultipleCountries, setReceivingFromMultipleCountries] = useState([]);
  const [sendingFromMultipleCountries, setSendingFromMultipleCountries] = useState([]);
  const [multipleAddresses, setMultipleAddresses] = useState([]);
  const [multipleIds, setMultipleIds] = useState([]);
  const [multiplePhoneNumbers, setMultiplePhoneNumbers] = useState([]);
  const [sameAddress, setSameAddress] = useState([]);
  const [sameIds, setSameIds] = useState([]);
  const [samePhoneNumber, setSamePhoneNumber] = useState([]);
  const [viewreport, setViewreport] = useState(true);
  const [transactionType, setTransactionType] = useState('SENT Transaction');

  const chartData = [
    multiplePhoneNumbers.length,
    multipleIds.length,
    multipleAddresses.length,
    samePhoneNumber.length,
    sameIds.length,
    sameAddress.length,
    sendingFromMultipleCountries.length,
    receivingFromMultipleCountries.length,
    manyToOne.length,
    oneToMany.length
  ]


  //console.log(user.user.attributes['custom:locationName'])
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
              backgroundColor: "#DEDDDB",
              color: "#000"
            }}
          >


            <Typography
              /* component="h6"
              variant="h6" */
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Welcome {user.user.attributes.name ?? ''}
            </Typography>
            <LogoutIcon color='error' onClick={user.signOut} />

            <Button variant='inherit' onClick={user.signOut} sx={{ flexDirection: 'column', px: [1] }}>
              Log Out
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <img src={LogoImage} alt="Logo" width="100%" height={open ? '120' : '12%'} />

          <Divider sx={{ my: 1 }} />
          <List component="nav">
            <MainListItems setViewreport={setViewreport} viewreport={viewreport} open={open} setOpen={setOpen} />

          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {
              loading
                ? <LoadingScreen />
                : (
                  <Grid container spacing={3}>

                    {/* Recent Deposits */}
                    <Grid item xs={7}>
                      <Paper sx={{ p: 5, mb: 2, height: 200, display: 'flex', flexDirection: 'column' }}>
                        <Filter
                          user={user}
                          data={data}
                          setTransactionType={setTransactionType}
                          defaultData={defaultData}
                          filteredData={filteredData}
                          setData={setData}
                          setFilteredData={setFilteredData}
                          setFilteredResult={setFilteredResult}
                          setSameAddress={setSameAddress}
                          setSameIds={setSameIds}
                          setSamePhoneNumber={setSamePhoneNumber}
                          setMultiplePhoneNumbers={setMultiplePhoneNumbers}
                          setMultipleAddresses={setMultipleAddresses}
                          setMultipleIds={setMultipleIds}
                          setManyToOne={setManyToOne}
                          setOneToMany={setOneToMany}
                          setReceivingFromMultipleCountries={setReceivingFromMultipleCountries}
                          setSendingFromMultipleCountries={setSendingFromMultipleCountries}
                        />
                      </Paper>

                      <Paper sx={{
                        p: 2, display: 'flex', flexDirection: 'column', borderRadius: '2.5%'
                      }}>
                        <Chart chartData={chartData} xs={12} />
                      </Paper>
                    </Grid>

                    <Grid item xs={5}>
                      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', borderRadius: '2%' }}>
                        <DefaultCollectionExample
                          user={user}
                          transactionType={transactionType}
                          data={data}
                          manyToOne={manyToOne}
                          oneToMany={oneToMany}
                          receivingFromMultipleCountries={receivingFromMultipleCountries}
                          sendingFromMultipleCountries={sendingFromMultipleCountries}
                          multipleAddresses={multipleAddresses}
                          multipleIds={multipleIds}
                          multiplePhoneNumbers={multiplePhoneNumbers}
                          sameAddress={sameAddress}
                          sameIds={sameIds}
                          samePhoneNumber={samePhoneNumber}
                        />
                      </Paper>
                    </Grid>
                  </Grid>
                )
            }

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default Dashboard;