import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import AWS from "aws-sdk";
import "./App.css";
import { useState, useEffect, Fragment } from "react";
import Dashboard from "./components/Dashboard";
import { Amplify, Auth } from "aws-amplify";
import {
  Authenticator,
  Button,
  Heading,
  Image,
  Text,
  View,
  useAuthenticator,
  useTheme,
} from "@aws-amplify/ui-react";
import awsExports from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import LogoImage from "./logo.jpeg";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Image alt="Bnb logo" src={LogoImage} width={160} height={100} />
      </View>
    );
  },

  Footer() {
    const { tokens } = useTheme();

    return (
      <View textAlign="center" padding={tokens.space.large}>
        <Text color={tokens.colors.neutral[80]}>
          &copy; Skaletek - All Rights Reserved
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          textAlign="center"
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Sign in to your account
        </Heading>
      );
    },
    Footer() {
      const { toResetPassword } = useAuthenticator();

      return (
        <View textAlign="center">
          <Button
            fontWeight="normal"
            onClick={toResetPassword}
            size="small"
            variation="link"
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          textAlign="center"
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Password Reset
        </Heading>
      );
    },
    /* Footer() {
      return <Text>Password Reset</Text>;
    }, */
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: "Enter your email",
      variation: "username",
    },
  },
  forceNewPassword: {
    password: {
      placeholder: "Enter your Password:",
    },
  },
  resetPassword: {
    username: {
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      placeholder: "Enter your Confirmation Code:",
      label: "New Label",
      isRequired: false,
    },
    confirm_password: {
      placeholder: "Enter your Password Please:",
    },
  },
  confirmSignIn: {
    confirmation_code: {
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};
function App() {
  const [transactionType, setTransactionType] = useState("");
  const [defaultData, setDefaultData] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState("");
  const [user, setUser] = useState(null); // State to hold user data
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getFile();
    checkUser(); // Check if the user is signed in
  }, []);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setUser(user); // Set the user state if the user is signed 
    } catch (error) {
      setUser(null); // Set the user state to null if the user is not signed in
    }
  };

  const [data, setData] = useState([]);
  const s3 = new AWS.S3({
    region: "us-east-1",
    accessKeyId: "AKIARPQ3CEMNCRF2DY4M",
    secretAccessKey: "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"
  });
  const bucketName = "bnb-compliance-bucket";
  const objectKey = "results/fraud_detector.json";
  const params = {
    Bucket: bucketName,
    Key: objectKey,
  };
  const getFile = async () => {
    setLoading(true);
    s3.getObject(params, (err, data) => {
      if (err) {
        console.error("Error retrieving JSON file:", err);
      } else {
        try {
          const jsonContent = JSON.parse(data.Body.toString());
          console.log("JSON Data:", jsonContent);
          setData(jsonContent);
          setLoading(false);
        } catch (jsonParseError) {
          console.error("Error parsing JSON:", jsonParseError);
        }
      }
    });
  };

  return (
    <Fragment>
      <Authenticator
        hideSignUp={true}
        formFields={formFields}
        components={components}
        initialState="signin"
      >
        {(user) => (
          <Dashboard
            transactionType={transactionType}
            data={data}
            defaultData={data}
            setTransactionType={setTransactionType}
            setSelectedFilter={setSelectedFilter}
            setData={setData}
            user={user}
            loading={loading}
          />
        )}
      </Authenticator>
    </Fragment>
  );
}

export default App;
