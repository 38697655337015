import React, { useState }  from 'react';
import { CChart } from '@coreui/react-chartjs';
import Title from './Title';
import { Autocomplete, Box, FormControl, FormHelperText, Grid, TextField } from '@mui/material';
import ResponsiveComponent from './Filter';
import Filter from './Filter';



export default function Chart({
  chartData
}) {

  return (
    <React.Fragment>
      <CChart
        type="bar"
        data={{
          labels: ['M.P', 'M.I', 'M.A', 'S.P', 'S.I', 'S.A', 'S.M.C', 'R.M.C', 'M.T.O', 'O.T.M'],
          datasets: [
            {
              label: 'Acitivty',
              data: chartData,
              backgroundColor: '#f87979',
              borderWidth: 1,
              borderRadius: '20%'
            },
          ],
        }}
        options={{
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        }}
      />
    </React.Fragment>
  );
}