import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import DataTable from './DataTable';
import { Autocomplete, FormHelperText, Grid, TextField } from '@mui/material';
import { CSVLink } from 'react-csv';

export default function TableLoadModal({ user, title, transactionType, data, showComponent, setShowComponent }) {
  const [maxWidth, setMaxWidth] = React.useState('md');
  const [inputLocationValue, setInputLocationValue] = React.useState('ALL');
  const [inputTransactionValue, setInputTransactionValue] = React.useState('ALL');
  const [inputCountryValue, setInputCountryValue] = React.useState('ALL');
  let locations = [];
  let countries = [];
  const [location, setLocation] = useState('ALL');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [country, setCountry] = useState('ALL');
  const [filteredResult, setFilteredResult] = useState(data);


  const userCountry = user.user.attributes['custom:country'];
  const userRole = user.user.attributes.profile;

  const handleClose = () => {
    setShowComponent(false);
  };

  if (data.length > 0) {
    data.forEach((obj) => {
      if (!locations.some((item) => item.locationName === obj.locationName)) {
        locations.push(obj);
      }
      if (transactionType === 'PAID Transaction') {
        if (!countries.some((item) => item.receivingCountry === obj.receivingCountry)) {
          countries.push(obj);
        }
      }

      if (transactionType === 'SENT Transaction') {
        if (!countries.some((item) => item.sendingCountry === obj.sendingCountry)) {
          countries.push(obj);
        }
      }

    });
  }
  console.log(countries)
  const handleStartDateChange = (value) => {
    setStartDate(value);
    const filtered = data.filter((item) => {
      if (!value || !endDate) {
        return true;
      }
      const date = item.paidDate ? item.paidDate : item.sentDate;
      return date.slice(0, 10) >= value && date.slice(0, 10) <= endDate;
    });
    setFilteredResult(filtered);
  };
  const handleEndDateChange = (value) => {
    setEndDate(value)
    const filtered = data.filter((item) => {
      if (!startDate || !value) {
        return true;
      }
      const date = item.paidDate ? item.paidDate : item.sentDate;
      return date.slice(0, 10) >= startDate && date.slice(0, 10) <= value;
    });
    setFilteredResult(filtered);
  };


  return (
    <React.Fragment>

      <Dialog
        fullWidth={true}
        maxWidth={maxWidth}
        open={showComponent}
        onClose={handleClose}
      >

        <Grid container styles={{
          margin: 40,
          display: 'flex',
          justifyContent: 'space-around'
        }} >
          <Grid item xs={12} sm={6} md={4}>
            <DialogTitle>{transactionType}</DialogTitle>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item xs={12} sm={6} md={4} className="btnContent">
            <CSVLink filename={"BNB monitoring.csv"}
              className="downloadbtn"
              data={filteredResult}
            >
              Download CSV
            </CSVLink>
          </Grid>
        </Grid>


        <DialogContent>
          {/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
          <Grid container styles={{
            marginTop: 40,
            display: 'flex',
            justifyContent: 'space-around'
          }} >

            <Grid item xs={12} sm={6} md={3}>
              <FormControl sx={{ m: 1 }}>
                <TextField
                  type="date"
                  value={startDate}
                  onChange={(e) => handleStartDateChange(e.target.value)}
                />
                <FormHelperText>Start Date</FormHelperText>
              </FormControl>

            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControl sx={{ m: 1 }}>
                <TextField
                  type="date"
                  value={endDate}
                  onChange={(e) => handleEndDateChange(e.target.value)}
                />
                <FormHelperText>End Date</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3} >
              <Autocomplete
                sx={{ m: 1 }}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setLocation(newValue);
                    const filtered = data.filter((item) => item.locationName === newValue.locationName);
                    setFilteredResult(filtered);
                  } else {
                    setLocation('ALL');
                    setFilteredResult(data);
                  }
                }}
                value={location}
                inputValue={inputLocationValue}
                onInputChange={(event, newInputValue) => {
                  setInputLocationValue(newInputValue);
                }}
                options={locations}
                getOptionLabel={(option) => option.locationName ?? ''}
                renderInput={(params) => <TextField {...params} label="Location" />}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {
                userRole === 'entity'
                  ? <Autocomplete
                    sx={{ m: 1 }}
                    disabled={true}
                    renderInput={(params) => <TextField {...params} label={userCountry} />}
                  />
                  : (<Autocomplete
                    sx={{ m: 1 }}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        //const filtered = (filteredResult.length > 0 ? filteredResult : data).filter((item) => item.sendingCountry === newValue.sendingCountry);
                        const filtered = data.filter((item) => {
                          if (transactionType === 'PAID Transaction') {
                            return item.receivingCountry === newValue.receivingCountry;
                          } else if (transactionType === 'SENT Transaction') {
                            return item.sendingCountry === newValue.sendingCountry;
                          } else {
                            // Handle other transaction types here
                            return false; // For example, return false for other types
                          }
                        });

                        setFilteredResult(filtered);
                        setCountry(newValue);
                      } else {
                        setCountry('ALL');
                        setFilteredResult(data);
                      }

                    }}
                    //value={country}
                    inputValue={inputCountryValue}
                    onInputChange={(event, newInputValue) => {
                      setInputCountryValue(newInputValue);
                    }}
                    options={countries}
                    getOptionLabel={(option) => {
                      if (transactionType === 'PAID Transaction') {
                        return option.receivingCountry;
                      } else if (transactionType === 'SENT Transaction') {
                        return option.sendingCountry;
                      } else {
                        return country
                      }
                    }

                    }
                    renderInput={(params) => <TextField {...params} label="Country" />}
                  />)
              }



            </Grid>

          </Grid >
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            <DataTable data={filteredResult} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}