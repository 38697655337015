import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, FormControl, FormHelperText, TextField, Grid, Typography } from '@mui/material';
import Title from './Title';
import { filterData } from '../utils/functions/dataUtils';



const Filter = ({
    defaultData,
    user,
    data,
    filteredData,
    setFilteredData,
    setFilteredResult,
    setData,
    setSameAddress,
    setSameIds,
    setSamePhoneNumber,
    setMultiplePhoneNumbers,
    setMultipleAddresses,
    setMultipleIds,
    setManyToOne,
    setOneToMany,
    setReceivingFromMultipleCountries,
    setSendingFromMultipleCountries,
    setTransactionType
}) => {
    let locations = [];
    let countries = [];
    const [inputLocationValue, setInputLocationValue] = React.useState('ALL');
    const [inputTransactionValue, setInputTransactionValue] = React.useState('ALL');
    const [inputCountryValue, setInputCountryValue] = React.useState('ALL');
    const [cases, setCases] = useState(0);

    const userRole = user.user.attributes.profile;
    const userLocationCode = user.user.attributes['custom:locationCode'];
    const userLocationName = user.user.attributes['custom:locationName'];
    const userCountry = user.user.attributes['custom:country'];

    if (filteredData.length > 0) {
        filteredData.forEach((obj) => {
            if (!locations.some((item) => item.locationName === obj.locationName)) {
                locations.push(obj);
            }
            if (!countries.some((item) => item.sendingCountry === obj.sendingCountry)) {
                countries.push(obj);
            }
        });
    }

    const filterSentData = (data) => {
        // same_address
        if (data[0].sent_data.sharing_patterns.same_address.length > 0) {
            const mapData = data[0].sent_data.sharing_patterns.same_address;
            let tempData = [];
            if (userRole === 'entity' || userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSameAddress(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSameAddress(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.sharing_patterns.same_address)
                setSameAddress(data[0].sent_data.sharing_patterns.same_address)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSameAddress([]);
        }
        //same_ids
        if (data[0].sent_data.sharing_patterns.same_ids.length > 0) {
            const mapData = data[0].sent_data.sharing_patterns.same_ids;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSameIds(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSameIds(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.sharing_patterns.same_ids)
                setSameIds(data[0].sent_data.sharing_patterns.same_ids)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSameIds([]);
        }
        // same_phone_number
        if (data[0].sent_data.sharing_patterns.same_phone_number.length > 0) {
            const mapData = data[0].sent_data.sharing_patterns.same_phone_number;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSamePhoneNumber(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSamePhoneNumber(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.sharing_patterns.same_phone_number)
                setSamePhoneNumber(data[0].sent_data.sharing_patterns.same_phone_number)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSamePhoneNumber([]);
        }
        // multiple_addresses
        if (data[0].sent_data.multiples_patterns.multiple_addresses.length > 0) {
            const mapData = data[0].sent_data.multiples_patterns.multiple_addresses;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setMultipleAddresses(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setMultipleAddresses(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.multiples_patterns.multiple_addresses)
                setMultipleAddresses(data[0].sent_data.multiples_patterns.multiple_addresses)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }

        } else {
            setFilteredData([]);
            setFilteredResult([]);
        }
        // multiple_ids
        if (data[0].sent_data.multiples_patterns.multiple_ids.length > 0) {
            const mapData = data[0].sent_data.multiples_patterns.multiple_ids;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setMultipleIds(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setMultipleIds(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.multiples_patterns.multiple_ids)
                setMultipleIds(data[0].sent_data.multiples_patterns.multiple_ids)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setMultipleIds([]);
        }
        // multiple_phone_numbers
        if (data[0].sent_data.multiples_patterns.multiple_phone_numbers.length > 0) {
            const mapData = data[0].sent_data.multiples_patterns.multiple_phone_numbers;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setMultiplePhoneNumbers(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setMultiplePhoneNumbers(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.multiples_patterns.multiple_phone_numbers)
                setMultiplePhoneNumbers(data[0].sent_data.multiples_patterns.multiple_phone_numbers)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }


        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setMultiplePhoneNumbers([]);
        }
        // Many to One
        if (data[0].sent_data.many_to_one.recipients.length > 0) {
            const mapData = data[0].sent_data.many_to_one.recipients;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setManyToOne(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setManyToOne(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.many_to_one.recipients)
                setManyToOne(data[0].sent_data.many_to_one.recipients)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setManyToOne([]);
        }

        // One to Many
        if (data[0].sent_data.one_to_many.senders.length > 0) {
            const mapData = data[0].sent_data.one_to_many.senders;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setOneToMany(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setOneToMany(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.one_to_many.senders)
                setOneToMany(data[0].sent_data.one_to_many.senders)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setOneToMany([]);
        }

        // receiving_from_multiple_countries
        if (data[0].sent_data.international_transactions.receiving_from_multiple_countries.length > 0) {
            const mapData = data[0].sent_data.international_transactions.receiving_from_multiple_countries;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setReceivingFromMultipleCountries(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setReceivingFromMultipleCountries(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.international_transactions.receiving_from_multiple_countries)
                setReceivingFromMultipleCountries(data[0].sent_data.international_transactions.receiving_from_multiple_countries)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setReceivingFromMultipleCountries([]);
        }
        // sending_from_multiple_countries
        if (data[0].sent_data.international_transactions.sending_from_multiple_countries.length > 0) {
            const mapData = data[0].sent_data.international_transactions.sending_from_multiple_countries;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.sendingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSendingFromMultipleCountries(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSendingFromMultipleCountries(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].sent_data.international_transactions.sending_from_multiple_countries)
                setSendingFromMultipleCountries(data[0].sent_data.international_transactions.sending_from_multiple_countries)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSendingFromMultipleCountries([]);
        }
    }

    const filterPaidData = (data) => {
        // same_address
        if (data[0].paid_data.sharing_patterns.same_address.length > 0) {
            const mapData = data[0].paid_data.sharing_patterns.same_address;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSameAddress(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSameAddress(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.sharing_patterns.same_address)
                setSameAddress(data[0].paid_data.sharing_patterns.same_address)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }

        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSameAddress([]);
        }
        //same_ids
        if (data[0].paid_data.sharing_patterns.same_ids.length > 0) {
            const mapData = data[0].paid_data.sharing_patterns.same_ids;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSameIds(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSameIds(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.sharing_patterns.same_ids)
                setSameIds(data[0].paid_data.sharing_patterns.same_ids)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSameIds([]);
        }
        // same_phone_number
        if (data[0].paid_data.sharing_patterns.same_phone_number.length > 0) {
            const mapData = data[0].paid_data.sharing_patterns.same_phone_number;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSamePhoneNumber(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSamePhoneNumber(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.sharing_patterns.same_phone_number)
                setSamePhoneNumber(data[0].paid_data.sharing_patterns.same_phone_number)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
                setSamePhoneNumber([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSamePhoneNumber([]);
        }
        // multiple_addresses
        if (data[0].paid_data.multiples_patterns.multiple_addresses.length > 0) {
            const mapData = data[0].paid_data.multiples_patterns.multiple_addresses;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setMultipleAddresses(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setMultipleAddresses(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.multiples_patterns.multiple_addresses)
                setMultipleAddresses(data[0].paid_data.multiples_patterns.multiple_addresses)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setMultipleAddresses([]);

        }
        // multiple_ids
        if (data[0].paid_data.multiples_patterns.multiple_ids.length > 0) {
            const mapData = data[0].paid_data.multiples_patterns.multiple_ids;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setMultipleIds(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setMultipleIds(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.multiples_patterns.multiple_ids)
                setMultipleIds(data[0].paid_data.multiples_patterns.multiple_ids)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setMultipleIds([]);
        }
        // multiple_phone_numbers
        if (data[0].paid_data.multiples_patterns.multiple_phone_numbers.length > 0) {
            const mapData = data[0].paid_data.multiples_patterns.multiple_phone_numbers;
            let tempDataMultiplePhoneNumbers = [];
            if (userRole === 'entity') {
                tempDataMultiplePhoneNumbers = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempDataMultiplePhoneNumbers)
                setMultiplePhoneNumbers(tempDataMultiplePhoneNumbers)
            } else if (userRole === 'agent') {
                tempDataMultiplePhoneNumbers = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempDataMultiplePhoneNumbers)
                setMultiplePhoneNumbers(tempDataMultiplePhoneNumbers)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.multiples_patterns.multiple_phone_numbers)
                setMultiplePhoneNumbers(data[0].paid_data.multiples_patterns.multiple_phone_numbers)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setMultiplePhoneNumbers([])
        }
        // Many to One
        if (data[0].paid_data.many_to_one.recipients.length > 0) {
            const mapData = data[0].paid_data.many_to_one.recipients;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setManyToOne(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setManyToOne(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.many_to_one.recipients)
                setManyToOne(data[0].paid_data.many_to_one.recipients)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setManyToOne([]);
        }

        // One to Many
        if (data[0].paid_data.one_to_many.senders.length > 0) {
            const mapData = data[0].paid_data.one_to_many.senders;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setOneToMany(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setOneToMany(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.one_to_many.senders)
                setOneToMany(data[0].paid_data.one_to_many.senders)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setOneToMany([]);
        }

        // receiving_from_multiple_countries
        if (data[0].paid_data.international_transactions.receiving_from_multiple_countries.length > 0) {
            const mapData = data[0].paid_data.international_transactions.receiving_from_multiple_countries;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setReceivingFromMultipleCountries(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setReceivingFromMultipleCountries(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.international_transactions.receiving_from_multiple_countries)
                setReceivingFromMultipleCountries(data[0].paid_data.international_transactions.receiving_from_multiple_countries)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setReceivingFromMultipleCountries([]);
        }
        // sending_from_multiple_countries
        if (data[0].paid_data.international_transactions.sending_from_multiple_countries.length > 0) {
            const mapData = data[0].paid_data.international_transactions.sending_from_multiple_countries;
            let tempData = [];
            if (userRole === 'entity') {
                tempData = mapData.filter((item) => {
                    return item.receivingCountry === userCountry;
                });
                setFilteredData(tempData)
                setSendingFromMultipleCountries(tempData)
            } else if (userRole === 'agent') {
                tempData = mapData.filter((item) => {
                    return item.locationCode === userLocationCode;
                });
                setFilteredData(tempData)
                setSendingFromMultipleCountries(tempData)
            }
            else if (userRole === 'admin') {
                setFilteredData(data[0].paid_data.international_transactions.sending_from_multiple_countries)
                setSendingFromMultipleCountries(data[0].paid_data.international_transactions.sending_from_multiple_countries)
            }
            else {
                setFilteredData([]);
                setFilteredResult([]);
            }
        } else {
            setFilteredData([]);
            setFilteredResult([]);
            setSendingFromMultipleCountries([]);
        }


    }

    const filterAllData = (data) => {
        setFilteredData([])
        if (userRole === 'entity') {
            // Same Phone Numbers
            let tempPaidDataSamePhoneNumber = [];
            let tempSentDataSamePhoneNumber = [];

            const paidDataSamePhoneNumber = data[0].paid_data.sharing_patterns.same_phone_number;
            const sentDataSamePhoneNumber = data[0].sent_data.sharing_patterns.same_phone_number;

            tempPaidDataSamePhoneNumber = paidDataSamePhoneNumber.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataSamePhoneNumber = sentDataSamePhoneNumber.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setSamePhoneNumber([
                ...tempPaidDataSamePhoneNumber,
                ...tempSentDataSamePhoneNumber
            ]);

            // Same Ids
            let tempPaidDataSameIds = [];
            let tempSentDataSameIds = [];
            const paidDataSameIds = data[0].paid_data.sharing_patterns.same_ids;
            const sentDataSameIds = data[0].sent_data.sharing_patterns.same_ids;

            tempPaidDataSameIds = paidDataSameIds.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataSameIds = sentDataSameIds.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setSameIds([
                ...tempPaidDataSameIds,
                ...tempSentDataSameIds
            ]);

            // Same Address
            let tempPaidDataSameAddress = [];
            let tempSentDataSameAddress = [];
            const paidDataSameAddress = data[0].paid_data.sharing_patterns.same_address;
            const sentDataSameAddress = data[0].sent_data.sharing_patterns.same_address;

            tempPaidDataSameAddress = paidDataSameAddress.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataSameAddress = sentDataSameAddress.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setSameAddress([
                ...tempPaidDataSameAddress,
                ...tempSentDataSameAddress
            ]);

            // Multiple Phone Numbers
            let tempPaidDataMultiplePhoneNumber = [];
            let tempSentDataMultiplePhoneNumber = [];

            const paidDataMultiplePhoneNumber = data[0].paid_data.multiples_patterns.multiple_phone_numbers;
            const sentDataMultiplePhoneNumber = data[0].sent_data.multiples_patterns.multiple_phone_numbers;

            tempPaidDataMultiplePhoneNumber = paidDataMultiplePhoneNumber.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataMultiplePhoneNumber = sentDataMultiplePhoneNumber.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setMultiplePhoneNumbers([
                ...tempPaidDataMultiplePhoneNumber,
                ...tempSentDataMultiplePhoneNumber
            ]);

            // Multiple Ids
            let tempPaidDataMultipleIds = [];
            let tempSentDataMutipleIds = [];
            const paidDataMultipleIds = data[0].paid_data.multiples_patterns.multiple_ids;
            const sentDataMultipleIds = data[0].sent_data.multiples_patterns.multiple_ids;

            tempPaidDataMultipleIds = paidDataMultipleIds.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataMutipleIds = sentDataMultipleIds.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setMultipleIds([
                ...tempPaidDataMultipleIds,
                ...tempSentDataMutipleIds
            ]);

            // Multiple Address
            let tempPaidDataMultipleAddress = [];
            let tempSentDataMultipleAddress = [];
            const paidDataMultipleAddress = data[0].paid_data.multiples_patterns.multiple_addresses;
            const sentDataMultipleAddress = data[0].sent_data.multiples_patterns.multiple_addresses;

            tempPaidDataMultipleAddress = paidDataMultipleAddress.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataMultipleAddress = sentDataMultipleAddress.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setMultipleAddresses([
                ...tempPaidDataMultipleAddress,
                ...tempSentDataMultipleAddress
            ]);

            // Sending from multiple countries
            let tempPaidDataSendingFromMultipleCountries = [];
            let tempSentDataSendingFromMultipleCountries = [];
            const paidDataSendingFromMultipleCountries = data[0].paid_data.international_transactions.sending_from_multiple_countries;
            const sentDataSendingFromMultipleCountries = data[0].sent_data.international_transactions.sending_from_multiple_countries;

            tempPaidDataSendingFromMultipleCountries = paidDataSendingFromMultipleCountries.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataSendingFromMultipleCountries = sentDataSendingFromMultipleCountries.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setSendingFromMultipleCountries([
                ...tempPaidDataSendingFromMultipleCountries,
                ...tempSentDataSendingFromMultipleCountries
            ]);

            // Receiving from multiple countries
            let tempPaidDataReceivingFromMultipleCountries = [];
            let tempSentDataReceivingFromMultipleCountries = [];
            const paidDataReceivingFromMultipleCountries = data[0].paid_data.international_transactions.receiving_from_multiple_countries;
            const sentDataReceivingFromMultipleCountries = data[0].sent_data.international_transactions.receiving_from_multiple_countries;

            tempPaidDataReceivingFromMultipleCountries = paidDataReceivingFromMultipleCountries.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataReceivingFromMultipleCountries = sentDataReceivingFromMultipleCountries.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setReceivingFromMultipleCountries([
                ...tempPaidDataReceivingFromMultipleCountries,
                ...tempSentDataReceivingFromMultipleCountries
            ]);

            // One to many
            let tempPaidDataOneToMany = [];
            let tempSentDataOneToMany = [];
            const paidDataOneToMany = data[0].paid_data.one_to_many.senders;
            const sentDataOneToMany = data[0].sent_data.one_to_many.senders;

            tempPaidDataOneToMany = paidDataOneToMany.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataOneToMany = sentDataOneToMany.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setOneToMany([
                ...tempPaidDataOneToMany,
                ...tempSentDataOneToMany
            ]);

            // Many to One
            let tempPaidDataManyToOne = [];
            let tempSentDataManyToOne = [];
            const paidDataManyToOne = data[0].paid_data.many_to_one.recipients;
            const sentDataManyToOne = data[0].sent_data.many_to_one.recipients;

            tempPaidDataManyToOne = paidDataManyToOne.filter((item) => {
                return item.receivingCountry === userCountry;
            });

            tempSentDataManyToOne = sentDataManyToOne.filter((item) => {
                return item.sendingCountry === userCountry;
            });

            setManyToOne([
                ...tempPaidDataManyToOne,
                ...tempSentDataManyToOne
            ]);

            let count = [
                ...tempPaidDataSamePhoneNumber,
                ...tempSentDataSamePhoneNumber,
                ...tempPaidDataSameIds,
                ...tempSentDataSameIds,
                ...tempPaidDataSameAddress,
                ...tempSentDataSameAddress,
                ...tempPaidDataMultiplePhoneNumber,
                ...tempSentDataMultiplePhoneNumber,
                ...tempPaidDataMultipleIds,
                ...tempSentDataMutipleIds,
                ...tempPaidDataMultipleAddress,
                ...tempSentDataMultipleAddress,
                ...tempPaidDataSendingFromMultipleCountries,
                ...tempSentDataSendingFromMultipleCountries,
                ...tempPaidDataReceivingFromMultipleCountries,
                ...tempSentDataReceivingFromMultipleCountries,
                ...tempPaidDataOneToMany,
                ...tempSentDataOneToMany,
                ...tempPaidDataManyToOne,
                ...tempSentDataManyToOne
            ]

            setCases(count.length);

        } else if (userRole === 'agent') {
            // Same Phone Numbers
            let tempPaidDataSamePhoneNumber = [];
            let tempSentDataSamePhoneNumber = [];

            const paidDataSamePhoneNumber = data[0].paid_data.sharing_patterns.same_phone_number;
            const sentDataSamePhoneNumber = data[0].sent_data.sharing_patterns.same_phone_number;

            tempPaidDataSamePhoneNumber = paidDataSamePhoneNumber.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataSamePhoneNumber = sentDataSamePhoneNumber.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setSamePhoneNumber([
                ...tempPaidDataSamePhoneNumber,
                ...tempSentDataSamePhoneNumber
            ]);


            // Same Ids
            let tempPaidDataSameIds = [];
            let tempSentDataSameIds = [];
            const paidDataSameIds = data[0].paid_data.sharing_patterns.same_ids;
            const sentDataSameIds = data[0].sent_data.sharing_patterns.same_ids;

            tempPaidDataSameIds = paidDataSameIds.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataSameIds = sentDataSameIds.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setSameIds([
                ...tempPaidDataSameIds,
                ...tempSentDataSameIds
            ]);

            // Same Address
            let tempPaidDataSameAddress = [];
            let tempSentDataSameAddress = [];
            const paidDataSameAddress = data[0].paid_data.sharing_patterns.same_address;
            const sentDataSameAddress = data[0].sent_data.sharing_patterns.same_address;

            tempPaidDataSameAddress = paidDataSameAddress.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataSameAddress = sentDataSameAddress.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setSameAddress([
                ...tempPaidDataSameAddress,
                ...tempSentDataSameAddress
            ]);

            // Multiple Phone Numbers
            let tempPaidDataMultiplePhoneNumber = [];
            let tempSentDataMultiplePhoneNumber = [];

            const paidDataMultiplePhoneNumber = data[0].paid_data.multiples_patterns.multiple_phone_numbers;
            const sentDataMultiplePhoneNumber = data[0].sent_data.multiples_patterns.multiple_phone_numbers;

            tempPaidDataMultiplePhoneNumber = paidDataMultiplePhoneNumber.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataMultiplePhoneNumber = sentDataMultiplePhoneNumber.filter((item) => {
                return item.locationCode === userLocationCode;
            });


            setMultiplePhoneNumbers([
                ...tempPaidDataMultiplePhoneNumber,
                ...tempSentDataMultiplePhoneNumber
            ]);

            // Multiple Ids
            let tempPaidDataMultipleIds = [];
            let tempSentDataMutipleIds = [];
            const paidDataMultipleIds = data[0].paid_data.multiples_patterns.multiple_ids;
            const sentDataMultipleIds = data[0].sent_data.multiples_patterns.multiple_ids;

            tempPaidDataMultipleIds = paidDataMultipleIds.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataMutipleIds = sentDataMultipleIds.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setMultipleIds([
                ...tempPaidDataMultipleIds,
                ...tempSentDataMutipleIds
            ]);

            // Multiple Address
            let tempPaidDataMultipleAddress = [];
            let tempSentDataMultipleAddress = [];
            const paidDataMultipleAddress = data[0].paid_data.multiples_patterns.multiple_addresses;
            const sentDataMultipleAddress = data[0].sent_data.multiples_patterns.multiple_addresses;

            tempPaidDataMultipleAddress = paidDataMultipleAddress.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataMultipleAddress = sentDataMultipleAddress.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setMultipleAddresses([
                ...tempPaidDataMultipleAddress,
                ...tempSentDataMultipleAddress
            ]);


            // Sending from multiple countries
            let tempPaidDataSendingFromMultipleCountries = [];
            let tempSentDataSendingFromMultipleCountries = [];
            const paidDataSendingFromMultipleCountries = data[0].paid_data.international_transactions.sending_from_multiple_countries;
            const sentDataSendingFromMultipleCountries = data[0].sent_data.international_transactions.sending_from_multiple_countries;

            tempPaidDataSendingFromMultipleCountries = paidDataSendingFromMultipleCountries.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataSendingFromMultipleCountries = sentDataSendingFromMultipleCountries.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setSendingFromMultipleCountries([
                ...tempPaidDataSendingFromMultipleCountries,
                ...tempSentDataSendingFromMultipleCountries
            ]);

            // Receiving from multiple countries
            let tempPaidDataReceivingFromMultipleCountries = [];
            let tempSentDataReceivingFromMultipleCountries = [];
            const paidDataReceivingFromMultipleCountries = data[0].paid_data.international_transactions.receiving_from_multiple_countries;
            const sentDataReceivingFromMultipleCountries = data[0].sent_data.international_transactions.receiving_from_multiple_countries;

            tempPaidDataReceivingFromMultipleCountries = paidDataReceivingFromMultipleCountries.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataReceivingFromMultipleCountries = sentDataReceivingFromMultipleCountries.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setReceivingFromMultipleCountries([
                ...tempPaidDataReceivingFromMultipleCountries,
                ...tempSentDataReceivingFromMultipleCountries
            ]);


            // One to many
            let tempPaidDataOneToMany = [];
            let tempSentDataOneToMany = [];
            const paidDataOneToMany = data[0].paid_data.one_to_many.senders;
            const sentDataOneToMany = data[0].sent_data.one_to_many.senders;

            tempPaidDataOneToMany = paidDataOneToMany.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataOneToMany = sentDataOneToMany.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setOneToMany([
                ...tempPaidDataOneToMany,
                ...tempSentDataOneToMany
            ]);

            // Many to One
            let tempPaidDataManyToOne = [];
            let tempSentDataManyToOne = [];
            const paidDataManyToOne = data[0].paid_data.many_to_one.recipients;
            const sentDataManyToOne = data[0].sent_data.many_to_one.recipients;

            tempPaidDataManyToOne = paidDataManyToOne.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            tempSentDataManyToOne = sentDataManyToOne.filter((item) => {
                return item.locationCode === userLocationCode;
            });

            setManyToOne([
                ...tempPaidDataManyToOne,
                ...tempSentDataManyToOne
            ]);

            let count = [
                ...tempPaidDataSamePhoneNumber,
                ...tempSentDataSamePhoneNumber,
                ...tempPaidDataSameIds,
                ...tempSentDataSameIds,
                ...tempPaidDataSameAddress,
                ...tempSentDataSameAddress,
                ...tempPaidDataMultiplePhoneNumber,
                ...tempSentDataMultiplePhoneNumber,
                ...tempPaidDataMultipleIds,
                ...tempSentDataMutipleIds,
                ...tempPaidDataMultipleAddress,
                ...tempSentDataMultipleAddress,
                ...tempPaidDataSendingFromMultipleCountries,
                ...tempSentDataSendingFromMultipleCountries,
                ...tempPaidDataReceivingFromMultipleCountries,
                ...tempSentDataReceivingFromMultipleCountries,
                ...tempPaidDataOneToMany,
                ...tempSentDataOneToMany,
                ...tempPaidDataManyToOne,
                ...tempSentDataManyToOne
            ]

            setCases(count.length);

        }
        else if (userRole === 'admin') {
            let count = [
                ...data[0].sent_data.one_to_many.senders,
                ...data[0].sent_data.many_to_one.recipients,
                ...data[0].sent_data.international_transactions.receiving_from_multiple_countries,
                ...data[0].sent_data.international_transactions.sending_from_multiple_countries,
                ...data[0].sent_data.multiples_patterns.multiple_addresses,
                ...data[0].sent_data.multiples_patterns.multiple_ids,
                ...data[0].sent_data.multiples_patterns.multiple_phone_numbers,
                ...data[0].sent_data.sharing_patterns.same_address,
                ...data[0].sent_data.sharing_patterns.same_ids,
                ...data[0].sent_data.sharing_patterns.same_phone_number,
                ...data[0].paid_data.many_to_one.recipients,
                ...data[0].paid_data.one_to_many.senders,
                ...data[0].paid_data.international_transactions.receiving_from_multiple_countries,
                ...data[0].paid_data.international_transactions.sending_from_multiple_countries,
                ...data[0].paid_data.multiples_patterns.multiple_addresses,
                ...data[0].paid_data.multiples_patterns.multiple_ids,
                ...data[0].paid_data.multiples_patterns.multiple_phone_numbers,
                ...data[0].paid_data.sharing_patterns.same_address,
                ...data[0].paid_data.sharing_patterns.same_ids,
                ...data[0].paid_data.sharing_patterns.same_phone_number
            ]

            setFilteredData([
                ...data[0].sent_data.one_to_many.senders,
                ...data[0].sent_data.many_to_one.recipients,
                ...data[0].sent_data.international_transactions.receiving_from_multiple_countries,
                ...data[0].sent_data.international_transactions.sending_from_multiple_countries,
                ...data[0].sent_data.multiples_patterns.multiple_addresses,
                ...data[0].sent_data.multiples_patterns.multiple_ids,
                ...data[0].sent_data.multiples_patterns.multiple_phone_numbers,
                ...data[0].sent_data.sharing_patterns.same_address,
                ...data[0].sent_data.sharing_patterns.same_ids,
                ...data[0].sent_data.sharing_patterns.same_phone_number,
                ...data[0].paid_data.many_to_one.recipients,
                ...data[0].paid_data.one_to_many.senders,
                ...data[0].paid_data.international_transactions.receiving_from_multiple_countries,
                ...data[0].paid_data.international_transactions.sending_from_multiple_countries,
                ...data[0].paid_data.multiples_patterns.multiple_addresses,
                ...data[0].paid_data.multiples_patterns.multiple_ids,
                ...data[0].paid_data.multiples_patterns.multiple_phone_numbers,
                ...data[0].paid_data.sharing_patterns.same_address,
                ...data[0].paid_data.sharing_patterns.same_ids,
                ...data[0].paid_data.sharing_patterns.same_phone_number
            ]);
            setSamePhoneNumber([
                ...data[0].paid_data.sharing_patterns.same_phone_number,
                ...data[0].sent_data.sharing_patterns.same_phone_number
            ]);
            setSameAddress([
                ...data[0].paid_data.sharing_patterns.same_address,
                ...data[0].sent_data.sharing_patterns.same_address
            ]);
            setSameIds([
                ...data[0].paid_data.sharing_patterns.same_ids,
                ...data[0].sent_data.sharing_patterns.same_ids
            ]);
            setMultiplePhoneNumbers([
                ...data[0].paid_data.multiples_patterns.multiple_phone_numbers,
                ...data[0].sent_data.multiples_patterns.multiple_phone_numbers
            ]);
            setMultipleAddresses([
                ...data[0].paid_data.multiples_patterns.multiple_addresses,
                ...data[0].sent_data.multiples_patterns.multiple_addresses
            ]);
            setMultipleIds([
                ...data[0].paid_data.multiples_patterns.multiple_ids,
                ...data[0].sent_data.multiples_patterns.multiple_ids
            ]);
            setManyToOne([
                ...data[0].paid_data.many_to_one.recipients,
                ...data[0].sent_data.many_to_one.recipients
            ]);
            setOneToMany([
                ...data[0].paid_data.one_to_many.senders,
                ...data[0].sent_data.one_to_many.senders
            ]);
            setReceivingFromMultipleCountries([
                ...data[0].paid_data.international_transactions.receiving_from_multiple_countries,
                ...data[0].sent_data.international_transactions.receiving_from_multiple_countries
            ]);
            setSendingFromMultipleCountries([
                ...data[0].paid_data.international_transactions.sending_from_multiple_countries,
                ...data[0].sent_data.international_transactions.sending_from_multiple_countries
            ]);

            setCases(count.length);
        }

    }


    const handleFraudtypeClick = (value) => {
        setTransactionType(value);

        // Sent transactions
        if (value === 'SENT Transaction') {
            // Many to One
            filterSentData(data)

        }
        // Received transaction
        else if (value === 'PAID Transaction') {
            filterPaidData(data)
        }
        else if (value === "ALL") {
            filterAllData(data)
        }
        else {
            setFilteredData([]);
            setFilteredResult([]);
        }
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day

    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];

    const day = String(currentDate.getDate()).padStart(2, '0');
    const monthName = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    const formattedDate = `${day} ${monthName}, ${year}`;
    // Output: "10 August, 2023" (for August 10, 2023)

    useEffect(() => {
        if (data.length > 0) {
            filterAllData(data);
        }

    }, [data]);

    return (
        <Grid container styles={{
            marginTop: 10,
            display: 'flex',
            justifyContent: 'space-between'
        }} >

            <Grid item xs={12} sm={6} md={6} >
                <Title>Total Cases</Title>
                <Typography component="p" variant="h4">
                    {cases}
                </Typography>
                <Typography color="text.secondary" sx={{ flex: 1 }}>
                    on {formattedDate}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} >
                <Autocomplete
                    sx={{ m: 1, p: 2, width: '100%' }}
                    onChange={(newValue) => {
                        //setTransactionType(newValue);
                    }}
                    value={inputTransactionValue}
                    inputValue={inputTransactionValue}
                    onInputChange={(event, newInputValue) => {
                        setTransactionType(newInputValue);
                        handleFraudtypeClick(newInputValue)
                        setInputTransactionValue(newInputValue);
                    }}
                    options={['ALL', 'SENT Transaction', 'PAID Transaction']}
                    renderInput={(params) => <TextField {...params} label="Transaction Type" />}
                />
            </Grid>

        </Grid >
    );
};

export default Filter;
